import React, { useMemo } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import { useLocation } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

type MenuLinkProps = {
  to: string
  text: string
  isAnchor?: boolean
}

const MenuLink = ({ text, to, isAnchor = false }: MenuLinkProps) => {
  const location = useLocation()
  const isCurrent = useMemo(() => {
    const pathname = location.pathname
    if (pathname === "/") {
      return to === pathname
    } else {
      return to.startsWith(location.pathname)
    }
  }, [location, to])

  return (
    isAnchor ? 
      <a href={to} tw="block p-4 text-2xl font-semibold duration-200 hover:bg-primary-darker hover:text-secondary-lighter">
        {text}
        <span tw="inline-block ml-2 text-base">
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </span>
      </a>
      :
      <Link
        to={to}
        tw="block p-4 text-2xl font-semibold duration-200 hover:bg-primary-darker hover:text-secondary-lighter"
        css={[isCurrent && tw`bg-primary-darkest`]}
      >
        {text}
      </Link>
  )
}

type LinkIconProps = {
  to: string
  icon: IconDefinition
}

const LinkIcon = ({ icon, to }: LinkIconProps) => {
  return (
    <a href={to} target="_blank" tw="text-2xl duration-200 hover:text-secondary-lighter">
      <FontAwesomeIcon icon={icon} />
    </a>
  )
}

const NavigationMenu = () => {
  return (
    <div>
      <ul tw="mb-6">
        <li>
          <MenuLink to="/" text="Home" />
        </li>
        <li>
          <MenuLink to="/links/" text="Links" />
        </li>
        <li>
          <MenuLink to="https://blog.yfsakai.com/" text="Blog" isAnchor />
        </li>
      </ul>

      <ul tw="flex justify-center gap-5">
        <li>
          <LinkIcon to="https://github.com/yusukefs" icon={faGithub} />
        </li>
        <li>
          <LinkIcon to="https://www.linkedin.com/in/yusuke-fred-sakai/" icon={faLinkedin} />
        </li>
      </ul>
    </div>
  )
}

export default NavigationMenu;
