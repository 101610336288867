import React, { useState } from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import tw from "twin.macro"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHamburger, faXmark } from '@fortawesome/free-solid-svg-icons'

import NavigationMenu from "./NavingationMenu"

const Wrapper = tw.div`bg-background`

type LayoutProps = {
  pageTitle: string
  children: React.ReactNode
}

const Title = () => {
  return (
    <Link to="/" tw="text-3xl md:mx-auto font-bold duration-200 hover:text-secondary-lighter">Yusuke Sakai</Link>
  )
}

const currentYear = new Date().getFullYear()

const Layout = ({ pageTitle, children }: LayoutProps) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const toggleNavigationOpen = () => {
    if (isNavigationOpen) {
      setIsNavigationOpen(false)
    } else {
      setIsNavigationOpen(true)
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{pageTitle} | Yusuke Sakai</title>
      </Helmet>

      <div tw="md:flex">
        <div tw="flex-none md:w-[300px] xl:w-[400px] md:h-[100vh] sticky top-0 bg-primary text-text-dark">
          <div tw="p-3 flex justify-between">
            <Title />
            <button onClick={toggleNavigationOpen} tw="md:hidden text-2xl w-10">
              <FontAwesomeIcon icon={isNavigationOpen ? faXmark : faHamburger} />
            </button>
          </div>
          <div css={[tw`pb-5`, !isNavigationOpen && tw`hidden md:display[unset]`]}>
            <div tw="mt-5 mb-8 w-10 h-1 mx-auto bg-background" />
            <NavigationMenu />
          </div>
        </div>

        <div tw="flex-grow">
          <main tw="container mx-auto px-3 pb-10 lg:px-20 xl:px-32">
            {children}
          </main>

          <footer tw="text-center mb-5">
            &copy; {currentYear} Yusuke Sakai
          </footer>
        </div>
      </div>
    </Wrapper>
  )
}

export default Layout
