import { Link } from "gatsby"
import tw from "twin.macro"

export const Section = tw.section`py-2 mt-16`
export const SubSection = tw.section`py-2 pl-1`
export const DefinitionList = tw.dl`ml-2`
export const DefinitionTerm = tw.dt`font-semibold`
export const DefinitionDescription = tw.dd`ml-5 mb-2`
export const Ul = tw.ul`list-disc pl-5`
export const TextLinkAnchor = tw.a`text-primary hover:underline hover:text-primary-darkest`
export const TextLink = tw(Link)`text-primary hover:underline hover:text-primary-darkest`
